<template>
    <component :is="wrapperComponent" :href="cardUrl" class="w-full space-y-4">
        <div class="relative w-full">
            <div
                class="flex items-center justify-center w-full p-8 overflow-hidden bg-white aspect-square rounded-2xl"
                :class="{
                    'brightness-50': isDarkened,
                }"
            >
                <picture
                    v-if="hasImage"
                    class="flex items-center justify-center w-full"
                >
                    <source
                        v-if="isWebP"
                        :srcset="product.thumbnail"
                        type="image/webp"
                    />
                    <img
                        :src="(!isWebP && product.thumbnail) || product.image"
                        :alt="product.name"
                    />
                </picture>
            </div>
            <div class="absolute top-0 left-0 flex w-full p-4">
                <slot name="actions" />
            </div>
        </div>
        <h2 class="h5">{{ product.name }}</h2>
        <div>
            <slot />
        </div>
    </component>
</template>

<script>
import { Link } from '@inertiajs/vue3';

export default {
    name: 'ProductCard',
    components: {
        Link,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        cardUrl: {
            type: String,
            required: false,
        },
        isDarkened: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        wrapperComponent() {
            return this.cardUrl ? Link : 'div';
        },
        isWebP() {
            return this.product?.thumbnail?.includes('.webp');
        },
        hasImage() {
            return this.product?.thumbnail || this.product?.image;
        },
    },
};
</script>
